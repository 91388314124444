import styled, { css } from 'styled-components'

const button = css`
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${props => props.theme.colors.light}
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy40ODMiIGhlaWdodD0iMjIuNzIzIiB2aWV3Qm94PSIwIDAgMTMuNDgzIDIyLjcyMyI+CiAgPHBhdGggZD0iTTE1Ny4zLDExNzguNjc2bC0xMC4zLDEwLjMsMTAuMywxMC4zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNTguMzYyIDEyMDAuMzM4KSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTAyNDNjIiBzdHJva2Utd2lkdGg9IjMiLz4KPC9zdmc+Cg==')
    no-repeat center;
  background-size: 10px 20px;
  transition: 0.2s background-color;
  z-index: 1;

  ${props =>
    props.white &&
    css`
      background-color: #fff;
    `}

  ${props =>
    props.centered &&
    css`
      position: absolute;
      top: calc(50% - 25px);
    `}

  ${props =>
    props.hideForMobile &&
    css`
      @media (max-width: ${props.theme.breakpoints.lg}) {
        display: none;
      }
    `}

  &:hover {
    background-color: ${props => props.theme.colors.primaryHover};
  }
`

const CustomPrevButton = styled.button`
  ${button};
  transform: rotate(180deg);

  ${props =>
    props.centered &&
    css`
      left: 10px;

      @media (min-width: ${props.theme.breakpoints.xxlUp}) {
        left: 5%;
      }
    `}
`

const CustomNextButton = styled.button`
  ${button};

  ${props =>
    props.centered &&
    css`
      right: 10px;

      @media (min-width: ${props.theme.breakpoints.xxlUp}) {
        right: 5%;
      }
    `}
`

export { CustomPrevButton, CustomNextButton }
